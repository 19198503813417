var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('h2',{staticClass:"pl-1"},[_vm._v("Заказы")]),_c('div',{staticClass:"d-flex justify-content-between col-12"},[_c('b-col',{staticClass:"my-1 px-0",attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-0"},[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-form-input',{attrs:{"id":"filterInput","type":"search","placeholder":"Искать"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(" Очистить ")])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","sort-icon-left":"","busy":_vm.isBusy,"items":_vm.items,"fields":_vm.fields},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-1"},[_vm._v("Идет загрузка ...")])],1)]},proxy:true},{key:"cell(button)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"delete__btn ml-2",attrs:{"variant":"outline-info"},on:{"click":function($event){return _vm.openDetails(item.id)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"18"}})],1)]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.statusTranslate(item.status)))])]}},{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.client.first_name + " " + item.client.last_name)+" ")]),_c('div',[_vm._v(_vm._s(item.client.phone))])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString("ru"))+" ")]),_c('div',[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleTimeString("ru"))+" ")])]}},{key:"cell(updated_at)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(new Date(item.updated_at).toLocaleDateString("ru"))+" ")]),_c('div',[_vm._v(" "+_vm._s(new Date(item.updated_at).toLocaleTimeString("ru"))+" ")])]}}])})],1),(_vm.items.length > 0)?_c('b-col',{staticClass:"mb-3 d-flex justify-content-between align-items-center",attrs:{"cols":"12"}},[_c('b-form-select',{staticClass:"float-right col-1",attrs:{"placeholder":"Выберите","options":_vm.pagination.perPageOptions},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.pagination.total,"per-page":_vm.pagination.perPage,"align":"center","size":"sm"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }